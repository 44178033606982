import request from '@/utils/request'
// pots  params query 传值 data body传值   data: JSON.stringify(data)

//  所有区域包括站点、大队、团队 树结构不带搜索 appName
export function regiontree(params) {
  return request({
    url: `/v2/api/app/ap_member/member/query/region/tree`,
    method: 'get',
    params
  })
}

// 活动中加载的服务类型树
export function servertree(params) {
  return request({
    url: `/v2/api/app/ap_member/app/peopleOrder/query_tree_app/app`,
    method: 'get',
    params
  })
}
//点单的创建
export function createPeopleOrder(data) {
  return request({
    url: `/v2/api/app/ap_member/app/peopleOrder/createPeopleOrder`,
    method: 'post',
    data: JSON.stringify(data)
  })
}
// 获取点单的详细信息
export function getPeopleOrderInfo(params) {
  return request({
    url: `/v2/api/app/ap_member/app/peopleOrder/getPeopleOrderInfo`,
    method: 'post',
    params
  })
}
// 点单的查询
export function selectPeopleOrder(params,data) {
  return request({
    url: `/v2/api/app/ap_member//app/peopleOrder/selectPeopleOrder?memberId=${params.memberId}&appName=${params.appName}`,
    method: 'post',
    data: JSON.stringify(data)
  })
}
// 获取点单的详细信息

export function getPeopleOrderInfomember(params) {
  return request({
    url: `/v2/api/app/ap_getPeopleOrderInfomember/app/peopleOrder/getPeopleOrderInfo`,
    method: 'post',
    params
  })
}
export default { regiontree, servertree, createPeopleOrder, getPeopleOrderInfo, selectPeopleOrder, getPeopleOrderInfomember}