<template>
  <div class="outerFrame">
    <div class="tabHead" v-if="show1||show2">  
      <div class="back iconfont icon-fanhui" @click="goback"></div>
      <span v-if="appName == '南陵文明'" @click="goToServe">服务介绍</span>
    </div>
    <div class="massorder" v-if="!show2&&!show1">
      <div class="tab">
        <span v-if="$route.query.backSign" class="first-back iconfont icon-fanhui" @click="goBackFiveOrder"></span>
        <div class="head_wrapper">
          <span @click="dd" :class="{'head_font': true,'head_border': active}">点单</span>
          <span @click="fanhui" :class="{'head_font': true,'head_border': !active}">反馈</span>
        </div>
      </div>
      <div class="content" v-if="active">
        <div class="itemCon">
          <van-cell-group>
            <van-field
              v-model="servename"
              required
              clearable
              :label=" appName === '爱旌德' ? '服务菜单' : '志愿服务需求类型'"
              :placeholder=" appName === '爱旌德' ? '请选择菜单' : '请选择服务'"
              @click="showPopup"
            />
            <van-field
              v-model="regionname"
              label="所属乡镇站点"
              placeholder="请选择所属乡镇"
              required
              @click="showState"
            />
            <van-field
              v-model="peopleOrderRemark"
              required
              rows="2"
              autosize
              label="活动需求"
              type="textarea"
              maxlength="100"
              placeholder="请输入活动需求"
              show-word-limit
            />
          </van-cell-group>
          <div class="font_need">*为必填项</div>
          <van-button round type="info" @click="pubOrder" class="submit">提 交</van-button>
        </div>
        <!-- 志愿服务类型 -->
      </div>
      <div class="callbackCon" v-else>
        <!-- 反馈列表 -->
        <div class="search_box">
          <van-dropdown-menu class="dropdownblock">
            <van-dropdown-item
              v-model="filtratedata.peopleOrderStatus"
              :options="option1"
              @change="changepeopleOrderStatus"
            />
          </van-dropdown-menu>
          <div class="switchblock">
          <Span>全部</span>  <van-switch :value="filtratedata.isMy" @input="changeisMy" size="28" active-color="#1377e2"/><Span>我的</span> 
          </div>
    
        </div>
        <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" class="mescrollDown gundong">
          <div
            v-for="(item,index) in fk"
            :key="index"
            class="item"
            :offset="10"
            @click="lookDetail(item)"
          >
            <div class="itemTop">
              <div class="title">{{ item.peopleOrderRemark }}</div>
              <div class="stateCode green" v-if="item.peopleOrderStatus=='03'">已反馈</div>
              <div class="stateCode red" v-else>待反馈</div>
            </div>
            <!-- 信息 -->
            <div class="subDetail">
              <div class="from">{{item.regionName}}{{item.serviceTypeName}}</div>
              <div class="time">{{item.createTime}}</div>
            </div>
          </div>
        </mescroll-vue>
      </div>
    </div>
    <tree class="treeHeight" :list="list" :treetype="1" v-if="show1" ></tree>
    <tree class="treeHeight" :list="serveList" v-if="show2"  :treetype="2" ></tree>
  </div>
</template>
<script>
// 引入mescroll的vue组件
import MescrollVue from "mescroll.js/mescroll.vue";
import { Toast,TreeSelect,Switch  } from "vant";
Toast.setDefaultOptions({ duration: 2500 });
import Bus from "../../bus";
import Tree from "./tree";
import peopleOrderApi from "@/api/peopleOrder";
export default {
  components: {
    MescrollVue,
    [Switch.name]:Switch,
    [Toast.name]:Toast,
    Tree

  },
  data() {
    return {
      offset: 100,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      opcityFlag: false,
      regionId: null,//点单的原部门
      serviceTypeId: null,//点单服务类型
      servename: "", //服务类型
      regionname: "", //志愿站点
      peopleOrderRemark: "", //活动需求
      //反馈页
      fk: [],
      active: true,
      serveList: [],
      show1: false,//区域显示 
      show2: false,//服务
      activeList: [], // 当前显示的站点大队数据
      surCount: "0", // 剩余点单数
      isShowCount: false,
      showFlag: false,
      isMy: false,
      is_fankui: true,
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, //下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: {
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 10, //每页数据条数,默认10
        },
        // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { } //以下是一些常用的配置,当然不写也可以的.
        // noMoreSize: 10,
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "emptyid0", //父布局的id (1.3.5版本支持传入dom元素)
          icon: "", //图标,默认null,支持网络图
          tip: "暂无数据", //提示
        },
      },

      // curActive: true,        // 当前是 站点数据还是大队数据 true 为站点数据 false 为大队数据
      allList: [],
      serveActiveList: [],
      keyObj: null,
      isError: false,
      serveKeyObj: null,
      list: [],
      option1: [
        { text: '全部状态', value: null },
        { text: '待反馈', value: '04' },
        { text: '已反馈', value: '03' },
      ],
      filtratedata: {
        desc: true,
        isMy: false,
        page: 0,
        peopleOrderStatus: null,
        rows: 15,
        sort: 'createTime',
      },
      memberId:0
    };
  },
  created() {
    this.getregiontree();
    this.getservertree();
    Bus.$on('regionTree', data => {
      this.getregionId(data)
    });
    Bus.$on('serviceTree', data => {
      this.getserviceTypeId(data)
    });
  },

  mounted() {
    var that = this;
    // 获取登录用户 手机号

  },
  methods: {
    goback(){
      this.show2=false
      this.show1=false
    },
    goBackFiveOrder() {
      this.$router.go(-1)
    },
    goToServe() {
      this.$router.push({
        name:'Mediareport',
        query:{
          columnId: localStorage.serveProjectId
        }
      })
    },
    //获取类型id
    getserviceTypeId(val){
      this.show2=false
      this.servename = val.name
       this.serviceTypeId = val.id
    },
        //获取区域id
    getregionId(val){
      this.show1=false
      this.regionname = val.name
       this.regionId = val.keyId
    },
    //筛选改变状态
    changepeopleOrderStatus() {
      let page = {
        num: 1, 
      }
      this.upCallback(page, this.mescroll)
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    upCallback(page, mescroll) {
      let that = this
      this.currentPage = page.num;
      this.mescroll = mescroll;
      this.filtratedata.page = page.num
      let params = {
        appName: this.appName,
        memberId: this.memberId 
      }
      let data = that.filtratedata
      peopleOrderApi.selectPeopleOrder(params, data).then(resp => {
        if (resp.success) {
          if (page.num === 1) {
            this.fk=[]
          }
          if (resp.data) {
            this.fk = this.fk.concat(resp.data.items);
          }
        } else {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr()
        }
        this.$nextTick(() => {
          if (resp.data.items) {
            mescroll.endSuccess(resp.data.items.length);
          } else {
            mescroll.endSuccess(0);
          }
        })
      }).catch(() => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        mescroll.endErr()
      })

    },
    // 切换筛选我的
    changeisMy(val){ 
      if(val){
        if(this.isToken()){
          this.filtratedata.isMy= true;
          this.memberId =window.localStorage.userinfo?JSON.parse(window.localStorage.userinfo).id:0
          let page = {num: 1}
          this.upCallback(page, this.mescroll)
        }
      }else{
        this.filtratedata.isMy= false
        this.memberId =0
        this.memberId =  0
        let page = {num: 1}
        this.upCallback(page, this.mescroll)
      }
    },
    showMore() {
      this.showFlag = !this.showFlag;
    },
    backLogin() {
      this.$router.go(-1);
    },
    dd() {
      this.active = true;
      // this.$router.push("/massorder");
    },
    //反馈
    fanhui() {
      this.active = false;
      //this.PeopleOrderList()
    },
    lookDetail(item) {
      //查看详情反馈
      if(this.isToken()){
        var link = window.location.href.split("#/")[0] +
                    `#/callbackDetail?id=${item.id}&page=${this.currentPage}`; //链接
        window.location.href = link;
      }
     //this.$router.push(`/callbackDetail?id=${item.id}&page=${this.currentPage}`);
    },
    //发布需求
    pubOrder() {
      if (!this.servename || !this.regionname||!this.peopleOrderRemark) {
        Toast(`请检查是否填写完整`);
        return;
      }
      this.opcityFlag = true;
      this.createPeopleOrder()
      let num = 60;
      this.submitMsg = `请${num}秒后再提交`;
      const timeId = setInterval(() => {
        num -= 1;
        this.submitMsg = `请${num}秒后再提交`;
      }, 1000);

      setTimeout(() => {
        clearInterval(timeId);
        this.opcityFlag = false;
      }, 60000);

    },
    showPopup() {
      this.show2 = true;
    },
    showState() {
      this.show1 = true;
    },
    //站点确认取消
    stateCancel() {
      this.show2 = false;
    },
    stateConfirm(value, index) {
      if (value[1].deptId > 0) {
        this.regionname = value[1].name.replace(/^[\d]*、/, "");
        this.regionId = value[1].deptId;
      } else {
        this.regionname = "";
        this.regionId = "";
      }
      this.show2 = false;
    },

    //新的接口业务

    //获取区域树
    getregiontree() {
      let that = this
      let params = {
        appName: this.appName
      }
      peopleOrderApi.regiontree(params).then(res => {
        if (res.success) {
          this.list = res.data
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    //获取服务类型
    getservertree() {
      let that = this
      let params = {
        appName: this.appName
      }
      peopleOrderApi.servertree(params).then(res => {
        if (res.success) {
          this.serveList = res.data
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    //点单的创建
    createPeopleOrder() {
      let that = this
      if(this.isToken()){
        let params = {
        memberId: JSON.parse(window.localStorage.userinfo).id,
        peopleOrderRemark: this.peopleOrderRemark,
        regionId: this.regionId,//点单的原部门
        serviceTypeId: this.serviceTypeId,//点单服务类型
      }
      peopleOrderApi.createPeopleOrder(params).then(res => {
        if (res.success) {
          Toast(`点单成功`);
          this.peopleOrderRemark = this.regionname = this.serviceTypeId = this.regionId = this.servename = "";
        } else {
          Toast(`${res.message}`);
        }
      })
      }
   
    },
    // selectPeopleOrder
    PeopleOrderList() {
      let that = this
      let params = {
        appName: this.appName,
        memberId: window.localStorage.userinfo?JSON.parse(window.localStorage.userinfo).id:'',
      }
      let data = that.filtratedata
      peopleOrderApi.selectPeopleOrder(params, data).then(res => {
        if (res.success) {
          console.log(res)
        } else {
          Toast(`${res.message}`);
        }
      })
    },
  },
  watch: {
    showFlag(nval) {
      if (nval) {
        this.$refs["switchlist"].style.transform = "rotate(180deg)";
      } else {
        this.$refs["switchlist"].style.transform = "rotate(0)";
      }
    },
  },
};
</script>

<style>
  #app .treeHeight{
    height: calc(100% - 0.7rem);
  }
  .tabHead{
    width: 100%;
    height: 0.7rem;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tabHead span {
    padding-right: 20px;
    font-weight: bold;
    font-size: 16px;
  }
  .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
    border-width: 0px 0px 1px 0px;
  }
  .itemCon .van-cell{
    padding: 15px 16px;
  }
</style>

<style lang="less">
@media screen and (min-width: 415px) {
 .back {
  padding:18px 16px
}
.itemCon {
  .van-field__label {
    width: 35vw !important;
    text-align: left;
    font-weight: 700;
    font-size: 0.16rem;
  }
  .surStyle {
    font-size: 12px;
    margin: 5px 0;
    white-space: nowrap;
    span {
      color: #ff4400;
    }
  }
  .font_need {
    color: #1377e2;
    font-size: 0.22rem;
    padding-left: 5vw;
    padding-top: 0.2rem;
  }
}
.massorder {
  height: 100%;
  .van-field__body {
    padding: 0 0 0 10px;
  }
  .back {
    width: 1rem;
    height: 0.7rem;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 1rem;
    font-size: 25px;
    color: #fff;
  }
  .opacty {
    opacity: 0.5;
  }
  .item {
    width: 90%;
    margin: 0px auto;
    padding: 12px 0px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  }
  .itemCon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 95%;
    }
  }
  .callbackCon {
    padding: 5px;
    height: calc(100% - 60px);
    .search_box {
      display: flex;
      border-bottom: 1px solid #eee;
     
    }
     .mescrollDown{
          height: calc(100% - 1rem);
      }
    .subDetail {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: space-between;
      color: #999;
      font-size: 13px;
      min-height: 25px;
      .from {
        flex: 1;
        overflow: hidden;
      }
      .time {
        font-size: 12px;
        margin-left: 10px;
      }
    }
    .itemTop {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      color: #444;
      .stateCode {
        // width: 1.3rem;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.5px;
        padding: 0px 5px;
      }
      .title {
        text-align: left;
        width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 15px;
      }
    }
  }
  .red {
    background: #ed0206;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    color: #fff;
    margin-top: 0.15rem;
  }
  .green {
    line-height: 26px;
    color: #fff;
    height: 26px;
    border-radius: 4px;
    background: #5ba72c;
    margin-top: 0.15rem;
  }
  .submit {
    width: 80%;
    font-size: 0.2rem;
    padding: 0.1rem 0px;
    margin-top: 0.3rem;
    // display: flex;
    // justify-content: center;
    // align-items:center;
    // height: 35px;
    // line-height: 35px;
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    font-size: 0.2rem;
    height: 0.7rem;
    line-height: 0.5rem;
    .head_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #1377e2;
      width: 44vw;
      height: 0.5rem;
      border-radius: 35px;
      .head_font {
        // border: 1px solid #1377e2;
        // background: #1377e2;
        width: 100%;
        height: 0.5rem;
        display: inline-block;
        border-radius: 18px;
        text-align: center;
        color: #1377e2;
        line-height: 0.5rem;
      }
      .head_border {
        border: 1px solid #1377e2;
        background: #1377e2;
        color: #fff;
      }
    }

    .callback {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .showmore {
      font-size: 16px;
      color: #fff;
      margin-left: 0.2rem;
      width: 0.5rem;
      height: 0.5rem;
      display: inline-block;
    }
    .order::before,
    .callback::before {
      margin-right: 5px;
    }
    .active {
      // background: red;
      background: rgba(0, 0, 0, 0.1);
    }
    div {
      width: 100%;
      height: 100%;
    }
  }
  .header {
    height: 64px;
    // background: #cd0102;
    background-image: url("../../assets/headBackground.png");
    // background-size:
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;
    // line-height: 64px;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
    box-sizing: border-box;
    padding-top: 20px;
    width: 100%;
    line-height: 40px;
  }

  // .zdPicker {
  //     .chooseBtn {
  //         span {
  //             padding: 3px 5px;
  //             &:first-child {
  //                 border-right: 1px solid #eee;
  //             }
  //         }
  //         span.activity {
  //             color: #f40;
  //         }
  //     }
  // }
}
.switchStyle {
  display: block;
  position: absolute;
  right: 10px;
  top: 22px;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  transition: 0.3s;
  transform: rotate(0deg);
  transform-origin: 50% 20%;
}
.fklist {
  position: absolute;
  padding: 10px 15px;
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  color: #424242;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #ccc;
  top: 100%;
  right: 10px;
  li {
    line-height: 25px;
    font-size: 14px;
  }
}
.itemCon {
  margin-top: 20px;
}
.switchblock{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span{
    padding:0 5px
 
  }
}
.dropdownblock{
  width: 50%;
  
  .van-dropdown-menu__item{
justify-content: flex-start;
  }
}
}

@media screen and (max-width: 415px) {
   .back {
  padding:18px 16px
}
.itemCon {
  .van-field__label {
    width: 35vw !important;
    text-align: left;
    font-weight: 700;
    font-size: 0.27rem;
  }
  .surStyle {
    font-size: 12px;
    margin: 5px 0;
    white-space: nowrap;
    span {
      color: #ff4400;
    }
  }
  .font_need {
    color: #1377e2;
    font-size: 0.22rem;
    padding-left: 5vw;
    padding-top: 0.2rem;
  }
}
.massorder {
  height: 100%;
  .van-field__body {
    padding: 0 0 0 10px;
  }
  .back {
    width: 1rem;
    height: 0.7rem;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 1rem;
    font-size: 25px;
    color: #fff;
  }
  .opacty {
    opacity: 0.5;
  }
  .item {
    width: 90%;
    margin: 0px auto;
    padding: 12px 0px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  }
  .itemCon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 95%;
    }
  }
  .callbackCon {
    padding: 5px;
    height: calc(100% - 60px);
    .search_box {
      display: flex;
      border-bottom: 1px solid #eee;
     
    }
     .mescrollDown{
          height: calc(100% - 1rem);
      }
    .subDetail {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: space-between;
      color: #999;
      font-size: 13px;
      min-height: 25px;
      .from {
        flex: 1;
        overflow: hidden;
      }
      .time {
        font-size: 12px;
        margin-left: 10px;
      }
    }
    .itemTop {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      color: #444;
      .stateCode {
        // width: 1.3rem;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.5px;
        padding: 0px 5px;
      }
      .title {
        text-align: left;
        width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 16px;
      }
    }
  }
  .red {
    background: #ed0206;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    color: #fff;
    margin-top: 0.15rem;
  }
  .green {
    line-height: 26px;
    color: #fff;
    height: 26px;
    border-radius: 4px;
    background: #5ba72c;
    margin-top: 0.15rem;
  }
  .submit {
    width: 80%;
    font-size: 0.35rem;
    // padding: 0.1rem 0px;
    margin-top: 0.3rem;
    // display: flex;
    // justify-content: center;
    // align-items:center;
    // height: 35px;
    // line-height: 35px;
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    font-size: 0.34rem;
    height: 0.8rem;
    line-height: 0.5rem;
    .first-back {
      position: fixed;
      left: 20px;
      top: 10px;
    }
    .head_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #1377e2;
      width: 44vw;
      height: 0.5rem;
      border-radius: 35px;
      .head_font {
        // border: 1px solid #1377e2;
        // background: #1377e2;
        width: 100%;
        height: 0.5rem;
        display: inline-block;
        border-radius: 18px;
        text-align: center;
        color: #1377e2;
        line-height: 0.5rem;
      }
      .head_border {
        border: 1px solid #1377e2;
        background: #1377e2;
        color: #fff;
      }
    }

    .callback {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .showmore {
      font-size: 16px;
      color: #fff;
      margin-left: 0.2rem;
      width: 0.5rem;
      height: 0.5rem;
      display: inline-block;
    }
    .order::before,
    .callback::before {
      margin-right: 5px;
    }
    .active {
      // background: red;
      background: rgba(0, 0, 0, 0.1);
    }
    div {
      width: 100%;
      height: 100%;
    }
  }
  .header {
    height: 64px;
    // background: #cd0102;
    background-image: url("../../assets/headBackground.png");
    // background-size:
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;
    // line-height: 64px;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
    box-sizing: border-box;
    padding-top: 20px;
    width: 100%;
    line-height: 40px;
  }

  // .zdPicker {
  //     .chooseBtn {
  //         span {
  //             padding: 3px 5px;
  //             &:first-child {
  //                 border-right: 1px solid #eee;
  //             }
  //         }
  //         span.activity {
  //             color: #f40;
  //         }
  //     }
  // }
}
.switchStyle {
  display: block;
  position: absolute;
  right: 10px;
  top: 22px;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  transition: 0.3s;
  transform: rotate(0deg);
  transform-origin: 50% 20%;
}
.fklist {
  position: absolute;
  padding: 10px 15px;
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  color: #424242;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #ccc;
  top: 100%;
  right: 10px;
  li {
    line-height: 25px;
    font-size: 15px;
  }
}
.itemCon {
  margin-top: 20px;
}
.switchblock{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span{
    padding:0 5px;
    font-size:14px;
  }
}
.dropdownblock{
  width: 50%;
  
  .van-dropdown-menu__item{
justify-content: flex-start;
  }
}
}
</style>
