<template>
  <div class="container">
    <div v-for="(item,index) in list" :key="index">
      <div class="wrapper_list">
        <span
          class="iconfont"
          :class="index==activeIndex?'icon-jian':'icon-jia2'"
          style="font-size:14px; padding-left:5px;color:#c9c4c4"
          @click="clickItem(index)"
          v-if="item.children"
        ></span>
        <span class="iconfont icon-dian" style="font-size:12px; padding-left:5px;color:#000" v-else></span>
        <div
          @click="selectItem(item,index)"
          class="name"
        >{{item.ugcTypeName ? item.ugcTypeName : item.name}}</div>
      </div>
      <div v-show="index==activeIndex" class="child_list">
        <tree :list="item.children" v-bind="$attrs" v-on="$listeners" :treetype="treetype"></tree>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '../../bus';

export default {
  name: "Tree",
  props: {
    list: Array,
    treetype: Number//1服务2区域
  },
  mounted() {
    this.arr = this.list;
    this.clickItem(0)
  },
  data() {
    return {
      activeIndex: -1,
    }
  },
  methods: {
    clickItem(index) {
      // this.showPlus = !this.showPlus;
      console.log(index);
      if (index == this.activeIndex) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },

    // 选择tree 1区域2服务
    selectItem(item, index) {
      console.log(item + ">>>>DSdds")
      if (this.treetype == 1) {
        Bus.$emit("regionTree", item)
      } else {
        this.findtree(item, index)
        // Bus.$emit("serviceTree", item)
      }

    },
    findtree(data, index) {
      if (data.children) {
        this.clickItem(index)
      } else {
        Bus.$emit("serviceTree", data)
      }
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 414px) {
  .container .wrapper_list .name {
    font-size: 16px;
  }
}

@media screen and (min-width: 415px) {
  .container .wrapper_list .name {
    font-size: 16px;
  }
}
.container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  .wrapper_list {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
  }
  .hasline {
  }
  .noline {
    padding-left: 20px;
  }
  .child_list {
    .wrapper_list {
      padding-left: 20px;
    }
    .child_list {
      .wrapper_list {
        padding-left: 40px;
      }
      .child_list {
        .wrapper_list {
          padding-left: 60px;
        }
        .child_list {
          .wrapper_list {
            padding-left: 80px;
          }
        }
        .child_list {
          .wrapper_list {
            padding-left: 100px;
          }
          .child_list {
            .wrapper_list {
              padding-left: 120px;
            }
            .child_list {
              .wrapper_list {
                padding-left: 140px;
              }
              .child_list {
                .wrapper_list {
                  padding-left: 160px;
                }
                .child_list {
                  .wrapper_list {
                    padding-left: 180px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .name {
    padding-left: 5px;
  }
}
</style>